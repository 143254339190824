import {
    FormControl,
    FormLabel,
    FormErrorMessage,
    Input,
    Center,
    Button,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
    Grid,
    Box,
    useMultiStyleConfig,
    Heading,
    Checkbox,
    useToast,
    HStack,
    Text
} from '@chakra-ui/react';
import React, { useEffect, useState, ChangeEvent, FormEvent } from 'react';
import { openCustomerModal, customerInfo } from 'src/redux/reducers/CustomerFormReducer';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import { useNavigate } from 'react-router-dom';
import { makeEnquiry } from 'src/redux/reducers/makeEnquiryReducer';
import Cookies from 'js-cookie';
// import gtag_report_conversion from 'src/getGtagReport';
import {eventCallingGA} from 'src/lib/ga';
import {eventCallingGAdWords} from 'src/lib/gaAdWords';
import {eventMeta} from 'src/lib/meta';
import { gtag_report_conversion } from 'src/lib/gtagConversation';



const styles = {
    title: {

        color: "gray",
        fontWeight: "medium",
        fontSize: { base: 'lg', xl: '3xl' },
        mb: { base: 4, xl: 6 },
        lineHeight: "tall",
        position: 'relative',
    },
    titleBorder: {
        display: { base: 'block', xl: 'none' },
        w: 36,
        borderBottom: '2px solid gray',
        pt: 2
    },
    text: {
        fontSize: { base: 'sm', xl: 'xl' },
        color: 'gray4',
        lineHeight: "tall",
        mb: 10
    },
    sectionBg: {
        bg: 'lightgray',
        pt: { base: 5, xl: 14 },
        pb: { base: 5, xl: 8 }
    },
    subTitle: {
        fontSize: { base: 'base', xl: 'black' },
        fontWeight: 'medium',
        mb: 6
    },
    formGrid: {
        gridTemplateColumns: { xl: 'repeat(2,1fr)' },
        rowGap: { base: 10, xl: 8 },
        columnGap: { xl: 16 },
        mb: { base: 10, xl: 24 }
    },
    formControl: {
        mb: 5,
        _last: {
            mb: 0
        }
    },
    // text-xs text-gray4 leading-snug
    notes: {
        fontSize: 'xs',
        color: 'gray4',
    },
    checkbox: {
        alignItems: "center",
        borderColor:"secondary",
        _hover: {
            color: "primary",
            borderColor: "primary70",
        }
    },
    myModal:{
        borderRadius:"none"
      }
}

interface EnquiryProps {
    open: any;
    close: any;
    payload: any;
}
export const MakeEnquiryModal: React.FC<EnquiryProps> = (props: any) => {
    const { close, open, payload } = props;
    const containerStyle = useMultiStyleConfig(`Container`);
    const dispatch = useAppDispatch();
    const toast = useToast();
    const [submitBtnLoading, setSubmitBtnLoading] = useState<boolean>(false);
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);
    const [firstName, setFirstName] = useState<string>("");
    const [lastname, setLastName] = useState<string>("");
    const [invalidFistName, setInvalidFirstName] = useState<boolean>(false);
    const [invalidLastName, setInvalidLastName] = useState<boolean>(false);
    const [mobileNo, setMobileNo] = useState<string>("");
    const [invalidMobile, setInvalidMobile] = useState<boolean>(false);
    const [email, setEmail] = useState<string>("");
    const [termsConditions, setTermsConditions] = useState<boolean>(false);
    const includesSubDomain = useAppSelector((state: any) => state.commonData.includesSubDomain);
    let customer = Cookies.get('cust');
    const validateMobileNo = (e: ChangeEvent<HTMLInputElement>) => {
        // const re = /^[0-9\b]+$/;
        // if (e.target.value === '' || re.test(e.target.value)) {
        //     setMobileNo(e.target.value)
        // }
        const mobileRegex = /^[1-9]\d{9}$/; // Regular expression for Indian mobile numbers
        const isValidMobile = mobileRegex.test(e.target.value);
        setInvalidMobile(!isValidMobile);
        setMobileNo(e.target.value);
    }

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (isFormSubmitted) {
            return;
          }
      
        setSubmitBtnLoading(true)
        // if (firstName.length === 0) {
        //     setInvalidFirstName(true)
        // }

        const params = {
            "first_name": firstName,
            "last_name": lastname,
            "cus_mobile": mobileNo,
            "cus_email": email,
            "city": payload.city,
            "make": payload.make,
            "model": payload.model,
            "variant": payload.variant,
            "lead_id": payload.leadId,
            "dealer_id": payload.dealerId,
            "terms_conditions": termsConditions,
            "platform": process.env.REACT_APP_PLATFORM_ID,
            "is_subdomain": includesSubDomain ? true : false,
            "umt_tracking_id" : Cookies.get('utmTrackID')

        }
        const res = dispatch(makeEnquiry(params));
        res.then((data: any) => {      
            if (data.payload.status  === "success") {
                // only for production
                // Trigger the Google Ads conversion event
                if (typeof window.gtag === 'function') {
                    window.gtag('event', 'conversion', {
                        'allow_custom_scripts': true,
                        'send_to': 'DC-9933106/audia00/audia0+standard'
                    });
                    console.log('Google Ads conversion event triggered - LEAD');
                } else {
                    console.error('gtag function not available LEAD');
                }
                // olx tracking
                const img = document.createElement('img');
                img.src = 'https://olx.gotrackier.com/pixel?adid=670cdbbbdd73a17ca05d8838';
                img.alt = 'Tracking Pixel';
                img.style.display = 'none';
                document.body.appendChild(img);
                gtag_report_conversion();
                // olx track ending
                if (process.env.REACT_APP_ENV=="prod")
                {
                    // eventCallingGA('DC-9933106/audia0/audia000+standard');
                    // console.log(window.dataLayer,"calling ga event");
                    // eventCallingGAdWords("AW-923778505/z-aZCOnp624Qyfu-uAM");
                    // console.log(window.dataLayer,"calling ga ad words event");
                    // eventMeta("389066024760021","Lead");
                    // console.log(window.dataLayer,"calling ga ad words event");
                    // if (window.location.hostname.includes('noida.audiapprovedplus')) {
                    //     fbq('init', '307433628486132');
                    //     fbq('track', 'PageView');
                    //     fbq('track', 'Lead');
                    // }
                // console.log("window.location.href",window.location.href);
                // gtag_report_conversion(window.location.href);
                //gtag('event', 'conversion', {'send_to': 'AW-923778505/z-aZCOnp624Qyfu-uAM'});
                }
                toast({
                    title: "Thank you for showing interest in us. Our representative will contact you shortly to confirm the appointment.",
                    status: 'success',
                    duration: 3000,
                    isClosable: true,
                    position:'bottom-right'
                })
                close();

            } else {
                toast({
                    title: data.payload.message,
                    status: 'warning',
                    isClosable: true,
                    position:'bottom-right'
                })
            }
        })
        setIsFormSubmitted(true);
        setSubmitBtnLoading(false);
    }
    const handleChecked = (e: ChangeEvent<HTMLInputElement>) => {
        const { checked }: any = e.target
    
        if (checked) {
            setTermsConditions(true);
        }
    }

    useEffect(() => {     
        if (customer) {
          const parsedCustomer = JSON.parse(customer);
          if (typeof parsedCustomer === 'object' && parsedCustomer !== null) {
            setFirstName(parsedCustomer.first_name);
            setLastName(parsedCustomer.last_name);
            setMobileNo(parsedCustomer.mobile);
            setEmail(parsedCustomer.email);
          }
        }
    }, [customer]);

    return (
        <Modal onClose={close} size={"xl"} isOpen={open} closeOnOverlayClick={false} scrollBehavior={"outside"}>
            <ModalOverlay />
            <ModalContent  sx={styles.myModal}>
                <ModalCloseButton />
                <ModalBody>
                    <Box my={8}>
                        <Box sx={containerStyle.wrapper}>
                            <Heading color="gray" mb={4} fontSize={"40px"}>Request call back</Heading>
                            <Box as='p' sx={styles.text}>Please complete the form below and a member of Our Team will get back to you.</Box>
                            <Box>
                                <form onSubmit={handleSubmit} id="myForm">
                                    <FormControl sx={styles.formControl} isRequired isInvalid={invalidFistName}>
                                        <FormLabel htmlFor='firstName'>First Name</FormLabel>
                                        <Input size='md' name='firstName' id="firstName" type='text' placeholder=" " value={firstName} onChange={(e: ChangeEvent<HTMLInputElement>) => setFirstName(e.target.value)} />
                                        <FormErrorMessage>Please add valid first name</FormErrorMessage>
                                    </FormControl>
                                    <FormControl sx={styles.formControl} isRequired isInvalid={invalidLastName}>
                                        <FormLabel htmlFor='lastName'>Last Name</FormLabel>
                                        <Input name='lastName' type='text' placeholder=" " value={lastname} onChange={(e: ChangeEvent<HTMLInputElement>) => setLastName(e.target.value)} />
                                    </FormControl>
                                    <FormControl sx={styles.formControl} isRequired isInvalid={invalidMobile}>
                                        <FormLabel htmlFor='mobile'>Mobile</FormLabel>
                                        <Input name='mobile' type='tel' placeholder=" "  maxLength={10} value={mobileNo} onChange={validateMobileNo} />
                                        <FormErrorMessage>Invalid mobile number</FormErrorMessage>
                                    </FormControl>
                                    <FormControl sx={styles.formControl} isRequired isInvalid={false}>
                                        <FormLabel htmlFor='email'>Email</FormLabel>
                                        <Input name='email' type='email' placeholder=" " value={email} onChange={(e: ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)} />
                                    </FormControl>
                                    <FormControl sx={styles.formControl} isRequired isInvalid={false}>
                                        <HStack>
                                        <Checkbox
                                            sx={styles.checkbox}
                                            onChange={(e) => handleChecked(e)} >
                                        </Checkbox>
                                        <Text> By submitting this form you are confirming that you agree with our Privacy Policy.</Text>
                                        </HStack>
                                    </FormControl>
                                    <Button type='submit' variant="primary" my={5} w={"full"} minW={60} maxW={"30%"}  isLoading={submitBtnLoading} loadingText='Submitting' disabled={invalidMobile}>Submit</Button>
                                </form>
                            </Box>
                        </Box>
                    </Box>
                </ModalBody>
            </ModalContent>
        </Modal>
    )
}


