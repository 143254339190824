export const gtag_report_conversion = (url) => {
  var callback = function () {
    if (typeof(url) !== 'undefined' && url !== '') {
      window.location = url;
    }
  };

  // Ensure `gtag` is loaded before calling it
  if (window.gtag) {
    window.gtag('event', 'conversion', {
      'send_to': 'AW-923778505/z-aZCOnp624Qyfu-uAM',
      'event_callback': callback
    });
    console.log("Conversion tracked");
  } else {
    console.error("Google Analytics is not initialized.");
  }

  return false;
};
